import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05d23176"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-overline text-capitalize" }
const _hoisted_2 = { class: "text-overline text-capitalize" }
const _hoisted_3 = { class: "row justify-center q-my-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_NotificacaoPostagem = _resolveComponent("NotificacaoPostagem")!
  const _component_NotificacaoAvaliacao = _resolveComponent("NotificacaoAvaliacao")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_NotificacaoCorrecao = _resolveComponent("NotificacaoCorrecao")!
  const _component_NotificacaoAula = _resolveComponent("NotificacaoAula")!
  const _component_NotificacaoAnexo = _resolveComponent("NotificacaoAnexo")!
  const _component_NotificacaoDuvida = _resolveComponent("NotificacaoDuvida")!
  const _component_NotificacaoRedacao = _resolveComponent("NotificacaoRedacao")!
  const _component_NotificacaoMensagem = _resolveComponent("NotificacaoMensagem")!
  const _component_q_spinner_dots = _resolveComponent("q-spinner-dots")!
  const _component_q_infinite_scroll = _resolveComponent("q-infinite-scroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_icon, {
        name: "push_pin",
        class: "rotate-315"
      }),
      _createTextVNode(" Fixadas ")
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificacoesFixadas, (notificacao) => {
      return (_openBlock(), _createBlock(_component_q_card, {
        key: notificacao.id,
        class: "card-feed",
        flat: "",
        bordered: ""
      }, {
        default: _withCtx(() => [
          (notificacao.type == 'postagem' && notificacao.relationship)
            ? (_openBlock(), _createBlock(_component_NotificacaoPostagem, {
                key: 0,
                notificacao: notificacao
              }, null, 8, ["notificacao"]))
            : _createCommentVNode("", true),
          (notificacao.type == 'avaliacao' && notificacao.relationship)
            ? (_openBlock(), _createBlock(_component_NotificacaoAvaliacao, {
                key: 1,
                notificacao: notificacao
              }, null, 8, ["notificacao"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_icon, { name: "sort" }),
      _createTextVNode(" Todos")
    ]),
    _createVNode(_component_q_infinite_scroll, {
      onLoad: _ctx.onLoad,
      offset: 250
    }, {
      loading: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_spinner_dots, {
            color: "primary",
            size: "40px"
          })
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificacoes, (notificacao) => {
          return (_openBlock(), _createElementBlock("div", {
            key: notificacao.id,
            class: "caption"
          }, [
            _createVNode(_component_q_card, {
              class: "card-feed",
              flat: "",
              bordered: ""
            }, {
              default: _withCtx(() => [
                (notificacao.type == 'avaliacao' && notificacao.relationship)
                  ? (_openBlock(), _createBlock(_component_NotificacaoAvaliacao, {
                      key: 0,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true),
                (notificacao.type == 'correcao' && notificacao.relationship)
                  ? (_openBlock(), _createBlock(_component_NotificacaoCorrecao, {
                      key: 1,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true),
                (notificacao.type == 'postagem' && notificacao.relationship)
                  ? (_openBlock(), _createBlock(_component_NotificacaoPostagem, {
                      key: 2,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true),
                (notificacao.type == 'aula' && notificacao.relationship)
                  ? (_openBlock(), _createBlock(_component_NotificacaoAula, {
                      key: 3,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true),
                (notificacao.type == 'anexo' && notificacao.relationship)
                  ? (_openBlock(), _createBlock(_component_NotificacaoAnexo, {
                      key: 4,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true),
                (notificacao.type == 'duvida' && notificacao.relationship)
                  ? (_openBlock(), _createBlock(_component_NotificacaoDuvida, {
                      key: 5,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true),
                (notificacao.type == 'redacao' && notificacao.relationship && notificacao.relationship.tipo_redacao.nome != 'simulado')
                  ? (_openBlock(), _createBlock(_component_NotificacaoRedacao, {
                      key: 6,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true),
                (notificacao.type == 'mensagem' && notificacao.relationship)
                  ? (_openBlock(), _createBlock(_component_NotificacaoMensagem, {
                      key: 7,
                      notificacao: notificacao
                    }, null, 8, ["notificacao"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoad"])
  ], 64))
}
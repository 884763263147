import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-overline text-capitalize" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_card_section, { horizontal: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, { class: "q-pt-xs" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.notificacao.type), 1),
            _createElementVNode("div", {
              innerHTML: _ctx.notificacao.relationship.texto
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_card_actions, { class: "row justify-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_chip, {
          icon: "event",
          size: "12px"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.notificacao.created_at), 1)
          ]),
          _: 1
        }),
        (_ctx.notificacao.relationship.file)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: `${_ctx.url}${_ctx.notificacao.relationship.file}`,
              class: "a-download",
              target: "_blank",
              style: {"text-decoration":"inherit"}
            }, [
              _createVNode(_component_q_chip, {
                clickable: "",
                icon: "download",
                color: "primary",
                "text-color": "white",
                size: "12px"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Baixar Anexo ")
                ]),
                _: 1
              })
            ], 8, _hoisted_3))
          : _createCommentVNode("", true),
        (!_ctx.notificacao.relationship.visualizado)
          ? (_openBlock(), _createBlock(_component_q_chip, {
              key: 1,
              clickable: "",
              icon: "check",
              color: "green",
              "text-color": "white",
              size: "12px",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.marcarPostagem(_ctx.notificacao.relationship, true)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Marcar como lida ")
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_q_chip, {
              key: 2,
              clickable: "",
              icon: "close",
              color: "red",
              "text-color": "white",
              size: "12px",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.marcarPostagem(_ctx.notificacao.relationship, false)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Marcar como não lida ")
              ]),
              _: 1
            }))
      ]),
      _: 1
    })
  ], 64))
}